body {
  background-color: #fff;
  margin: 0;
  margin-bottom: 40px;
}

.product.card img {
  height: 200px;
  max-width: 100%;
  display: block;
  margin: 20px auto 0px auto;
}

.app-logo-spinner {
  animation: App-logo-spin infinite 2s linear;
  width: 80px;
  height: auto;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hide-tablet {
    display: none;
  }
}

@media (max-width: 767px) {
  .hide-mobile {
    display: none!important;
  }
}

.breadcrumbs {
  margin: 10px 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

.breadcrumbs .breadcrumb {
  display: inline-block;
  line-height: 30px;
}

.breadcrumbs .breadcrumb a {
  color: rgb(0, 136, 206);
  text-decoration: none;
}

.breadcrumbs .breadcrumb:after {
  content: '>';
  color: rgb(0, 0, 0);
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  margin: 0 10px 0 8px;
  -webkit-font-smoothing: antialiased;
}

.breadcrumbs .breadcrumb:last-child:after {
  display: none;
}

.breadcrumbs .breadcrumb:last-child {
  cursor: default;
}

.react-swipeable-view-container > div[aria-hidden="false"] {
  height: 100%;
}

.react-swipeable-view-container > div[aria-hidden="true"] {
  height: 0;
}
