.cron-builder {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  padding: 10px 5px;
}

.cron-builder__tab {
  color: white;
  background-color: #004b75;
  background-image: none;
  background-position: center;
  margin: 0;
  padding: 2px 7px;
  transition: background 0.8s;
  height: 36px;
  outline: none;
}

.cron-builder__tab--active {
  color: white;
  background-color: #0088ce;
  background-size: 100%;
  transition: background 0s;
  outline: none;
  border-top: 2px solid #0088ce;
  border-bottom: 2px solid #f19a03;
}

.cron-builder__tab:hover:not(.cron-builder__tab--active) {
  background: #0088ce radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
  outline: none;
}

.cron-builder__action {
  outline: none;
}

.cron-builder__fieldset {
  border: 0;
}

.cron-builder__action {
  background-image: none;
  background-color: #004b75;
  color: white;
}

.cron-builder__result {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.cron-builder__pretty-expression {
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cron-builder__label {
  color: rgba(0, 0, 0, 0.87);
}

.cron-builder__input {
  min-width: 80px;
}

.cron-builder__hr {
  border-top: 1px solid rgb(224, 224, 224);
}



